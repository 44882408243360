import useGetTickets from "../hooks/useGetTickets";
import { PrimaryButton, Text } from "flicket-ui";
import {
  PageContext,
  competitionFromPageContext,
  eventPhaseFromPageContext,
  isOnsaleCompetitionContext,
  isOnsaleContext,
  isRegistrationCompetitionContext,
  isRegistrationContext,
} from "../hooks/useDetailsPageContext";
import useCaptureClick from "~telemetry/traces/useCaptureClick";
import {
  AttributeNames,
  InteractionNames,
} from "~telemetry/enums/AttributeNames";

export default function MainCTA(props: { pageContext: PageContext }) {
  const { pageContext } = props;
  const { navigate, url } = useGetTickets(pageContext.event.id);

  const competitionId = competitionFromPageContext(pageContext)?.id;

  const captureClick = useCaptureClick({
    forwardToNest: true,
    eventId: pageContext.event.id,
    pageName: "event-details-page",
    attributes: {
      [AttributeNames.FLICKET_COMPETITION_ID]: competitionId,
      [AttributeNames.FLICKET_EVENT_PHASE]: eventPhaseFromPageContext(
        pageContext
      ),
    },
  });

  const handleClickBuyTickets = () => navigate();

  if (isOnsaleContext(pageContext) || isOnsaleCompetitionContext(pageContext)) {
    return (
      <PrimaryButton
        width="100%"
        to={url}
        onClick={captureClick(
          handleClickBuyTickets,
          InteractionNames.BUTTON_PRIMARY,
          {
            [AttributeNames.BUTTON_LABEL]: "Buy tickets",
          }
        )}
      >
        <Text fontSize={3}>Buy tickets</Text>
      </PrimaryButton>
    );
  }

  if (
    isRegistrationContext(pageContext) ||
    isRegistrationCompetitionContext(pageContext)
  ) {
    return (
      <PrimaryButton
        width="100%"
        to={`/events/${pageContext.event.id}/registration`}
        onClick={captureClick(undefined, "register-button")}
      >
        <Text fontSize={3}>Register</Text>
      </PrimaryButton>
    );
  }

  return null;
}
